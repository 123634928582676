import { default as indexKrz8aDLwDoMeta } from "/src/pages/[...id]/enrollment/index.vue?macro=true";
import { default as indexugG41fYiaoMeta } from "/src/pages/[...id]/enrollment/raffleTerms/index.vue?macro=true";
import { default as indextpr9vyh4laMeta } from "/src/pages/[...id]/enrollment/raffleTermsEn/index.vue?macro=true";
import { default as indexLjCa9TYT80Meta } from "/src/pages/[...id]/securityToken/index.vue?macro=true";
import { default as _91id_93q961D01iaEMeta } from "/src/pages/[id].vue?macro=true";
import { default as index3d232f3Hx1Meta } from "/src/pages/enrollment/confirmation/index.vue?macro=true";
import { default as indexu7gclxVNzdMeta } from "/src/pages/enrollment/success/index.vue?macro=true";
import { default as indexHW787ta9qGMeta } from "/src/pages/enrollment/unlink/index.vue?macro=true";
import { default as indexRupEYGeNhcMeta } from "/src/pages/enrollment/unlink/success/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91id_938ssaCihBpFMeta } from "/src/pages/qrcode/[id].vue?macro=true";
export default [
  {
    name: "id-enrollment___en",
    path: "/en/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___de___default",
    path: "/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___de",
    path: "/de/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment___fr",
    path: "/fr/:id(.*)*/enrollment",
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___en",
    path: "/en/:id(.*)*/enrollment/raffleTerms",
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___de___default",
    path: "/:id(.*)*/enrollment/raffleTerms",
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___de",
    path: "/de/:id(.*)*/enrollment/raffleTerms",
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTerms___fr",
    path: "/fr/:id(.*)*/enrollment/raffleTerms",
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___en",
    path: "/en/:id(.*)*/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___de___default",
    path: "/:id(.*)*/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___de",
    path: "/de/:id(.*)*/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-enrollment-raffleTermsEn___fr",
    path: "/fr/:id(.*)*/enrollment/raffleTermsEn",
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___en",
    path: "/en/:id(.*)*/securityToken",
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___de___default",
    path: "/:id(.*)*/securityToken",
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___de",
    path: "/de/:id(.*)*/securityToken",
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id-securityToken___fr",
    path: "/fr/:id(.*)*/securityToken",
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: "id___en",
    path: "/en/:id()",
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "id___de___default",
    path: "/:id()",
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "id___de",
    path: "/de/:id()",
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "id___fr",
    path: "/fr/:id()",
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___en",
    path: "/en/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___de___default",
    path: "/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___de",
    path: "/de/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-confirmation___fr",
    path: "/fr/enrollment/confirmation",
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___en",
    path: "/en/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___de___default",
    path: "/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___de",
    path: "/de/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-success___fr",
    path: "/fr/enrollment/success",
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___en",
    path: "/en/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___de___default",
    path: "/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___de",
    path: "/de/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink___fr",
    path: "/fr/enrollment/unlink",
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___en",
    path: "/en/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___de___default",
    path: "/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___de",
    path: "/de/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "enrollment-unlink-success___fr",
    path: "/fr/enrollment/unlink/success",
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de___default",
    path: "/",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___en",
    path: "/en/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___de___default",
    path: "/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___de",
    path: "/de/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: "qrcode-id___fr",
    path: "/fr/qrcode/:id()",
    component: () => import("/src/pages/qrcode/[id].vue").then(m => m.default || m)
  }
]